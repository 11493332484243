import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Section from '../common/Section';
import { richText as prismicRichText } from '../../shapes/prismic';
import { RichText } from '../common/prismic';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(6),
        textAlign: 'center',
        backgroundColor: theme.palette.grey[50],
        height: '100%',
    },
    cardTitle: {
        paddingBottom: theme.spacing(4),
    },
    cardDesc: {
        textAlign: 'left',
        paddingBottom: theme.spacing(5),
    },
    link: {
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    emailLink: {
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    linkWithoutQuote: {
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(4),
    },
    linkText: {
        paddingBottom: theme.spacing(1),
    },
    grid: {
        paddingTop: theme.spacing(4),
    },
    button: {
        marginTop: theme.spacing(1),
        width: 240,
        [theme.breakpoints.down('sm')]: {
            width: 220,
        },
    },
    noBackground: {
        boxShadow: 'none',
    },
    label: {
        fontSize: 18,
        fontWeight: 600,
    },
    emailText: {
        color: theme.palette.common.white,
    },
}));

function InformationRow({ informationCardItems }) {
    const classes = useStyles();
    return (
        <Section>
            <Grid container spacing={5} justify="space-around">
                {informationCardItems.map((item, i) => (
                    <Grid item xs={12} sm={8} key={`infoCard${i + 1}`}>
                        <Paper className={classes.paper}>
                            {item.card_title && (
                                <Typography
                                    variant="h3"
                                    className={classes.cardTitle}
                                >
                                    {item.card_title}
                                </Typography>
                            )}

                            <Typography
                                variant="body1"
                                component="span"
                                className={classes.cardDesc}
                            >
                                <RichText html={item.card_description.html} />
                            </Typography>
                            <a
                                href={item.card_phone.url}
                                className={classes.link}
                            >
                                <Typography
                                    variant="h4"
                                    color="primary"
                                    className={classes.linkText}
                                >
                                    {item.card_phone_number}
                                </Typography>
                            </a>
                            <a
                                href={item.card_email.url}
                                className={classes.emailLink}
                            >
                                <Typography
                                    variant="h4"
                                    color="primary"
                                    className={classes.linkText}
                                >
                                    {item.card_email_address}
                                </Typography>
                            </a>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Section>
    );
}
InformationRow.propTypes = {
    informationCardItems: PropTypes.arrayOf(
        PropTypes.shape({
            card_description: prismicRichText,
            card_email: PropTypes.object,
            card_phone: PropTypes.object,
            card_title: PropTypes.string,
            show_quote_button: PropTypes.bool,
            card_phone_number: PropTypes.string,
            card_email_address: PropTypes.string,
        })
    ),
}.isRequired;

export default InformationRow;
