import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { Layout } from '../components/common';
import { richText as prismicRichText } from '../shapes/prismic';
import { withPreviewData } from '../util/preview';
import PrismicImage from '../models/prismicImage';
import InformationRow from '../components/contact-us/InformationRow';
import { mobileNavBreakpoint } from '../components/common/NavBar/constants';
import { FluidImage } from '../components/common/Image';

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingTop: theme.spacing(13),
        textAlign: 'center',
        [`@media (max-width:${mobileNavBreakpoint}px)`]: {
            paddingTop: theme.spacing(8),
        },
    },
    sectionBreak: {
        height: '30vh',
    },
}));

function ContactUsPage({ data: staticData, location }) {
    const data = withPreviewData(staticData);
    const classes = useStyles();
    const {
        description,
        heading,
        information_card_group: informationCardGroup,
        json_ld: jsonLD,
        title,
        section_break_image: sectionBreakImage,
    } = data.prismicContactUs.data;

    return (
        <Layout
            location={location}
            customMetaData={{
                title,
                description,
                jsonLD: (jsonLD || {}).text,
            }}
            navBarProps={{
                scrollThreshold: 0,
            }}
        >
            <FluidImage
                className={classes.sectionBreak}
                objectPosition="50% 80%"
                image={new PrismicImage(sectionBreakImage)}
                maxWidth={1920}
            />
            <Typography variant="h1" className={classes.heading}>
                {heading}
            </Typography>
            <InformationRow informationCardItems={informationCardGroup} />
        </Layout>
    );
}

export default ContactUsPage;

ContactUsPage.propTypes = {
    data: PropTypes.shape({
        prismicContactUs: PropTypes.shape({
            data: PropTypes.shape({
                description: PropTypes.string,
                heading: PropTypes.string,
                information_card_group: PropTypes.arrayOf(
                    PropTypes.shape({
                        card_description: prismicRichText,
                        card_email: PropTypes.object,
                        card_phone: PropTypes.object,
                        card_title: PropTypes.string,
                        show_quote_button: PropTypes.bool,
                        card_phone_number: PropTypes.string,
                        card_email_address: PropTypes.string,
                        email_us_text: PropTypes.string,
                    })
                ),
                json_ld: prismicRichText,
                title: PropTypes.string,
                section_break_image: PropTypes.object,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
    query ContactUsQuery {
        prismicContactUs {
            data {
                description
                heading
                information_card_group {
                    card_description {
                        html
                        text
                    }
                    card_email {
                        url
                    }
                    card_email_address
                    card_phone {
                        url
                    }
                    card_phone_number
                    card_title
                    email_us_text
                    show_quote_button
                }
                json_ld {
                    html
                    text
                }
                title
                section_break_image {
                    ...ProPrismicImageMetadata_NoBase64
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;
